import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";


function ServicesOffered() {

  const data = useStaticQuery(
      graphql`
      query getFooterServices {
          allFooterServicesContent: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "service-page" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                }
              }
            }
          }
        }
    `
  )
  let servicesList = [
    ...new Map(
        data.allFooterServicesContent.edges.map((item) => [item.node.id, item])
    ).values(),
  ];

  return (
          <div>
            <div>
              <div className="lg:mt-0 mt-6">
                <h4 className="text-sm leading-5 font-bold tracking-wider text-brand-600 uppercase font-display">
                Services
                </h4>

                <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">
                {servicesList.map((service,i ) => {
                    const {title, slug} = service.node.frontmatter
                  return (
                   <li key={i} className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                     <svg className="text-brand-500 w-5 h-5 mr-2"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>

                        <Link to={`/${slug}/`} title={`${title} Sacramento Valley`}>{title}</Link>
                   </li>
                  );
                })}
                </ul>
              </div>
            </div>
          </div>
  );
}

export default ServicesOffered;
