import { graphql, useStaticQuery } from 'gatsby'
import contactInfo from "../settings/contact_info.json";

const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY { 
        contactInfo: settingsJson(companynamefull: { ne: null }) {
          addressLine1
          addressLine2
          description
          services
          websiteurl
          city
          companyname
          companynamefull
          license
          email
          phonenumber
          slogan
          state
          zip
          subtagline
          altslogan
          facebookurl
          instagramurl
          googleurl
          yelpurl
          guildqualityurl 
        } 
       
         advancedSettings: settingsJson(reviewscount: { ne: null }) {
           reviewavg
            reviewscount
            ratingstarsdisplayenabled
            gatrackingid
            developmentMode
            serviceherostyle
            mainnavigationstyle
          }
      }
    `
  )
  return {...data.contactInfo, ...data.advancedSettings}
}

export default useSiteMetadata
