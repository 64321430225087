import React, { useState, useEffect } from "react";
import { StaticQuery, graphql, Link } from "gatsby"; 
import { Transition } from "@headlessui/react";
import MenuItemDropdown from "./MenuItemDropdown";
import ContactFormModal from "./ContactFormModal"; 
import useSiteMetadata from './SiteMetadata' 
import brandLogos from '../settings/company_brand.json'

function Navbar({headerTransparent, bottomBorder}) {  

  const { companyname, description, slogan, altslogan, subtagline, email, phonenumber, mainnavigationstyle} = useSiteMetadata()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  useEffect(() => {
    if(mobileMenuOpen) {
      document.body.classList.add('menu-expanded');
    }
 
    return function cleanup() {
      document.body.classList.remove('menu-expanded');
    };
   
  }, [mobileMenuOpen]);

  const MenuItem = ({ item, type, source, headerTransparent}) => {
    let itemId = null;
    let title = null;
    let slug = null;

    switch (type) {
      case "pageitem":
        itemId = item.page.frontmatter.id;
        title = item.page.frontmatter.title;
        slug = item.page.frontmatter.slug;
        break;
      case "servicepageitem":
        itemId = item.service.frontmatter.id;
        title = item.service.frontmatter.title;
        slug = item.service.frontmatter.slug;
        break;
      case "customurl":
        itemId = item.id;
        title = item.title;
        slug = item.pageurl;
        break;
    }

    switch (type) {
      case "dropdownonesection":
        return <MenuItemDropdown item={item} label={item.label} type={1} source={source} headerTransparent={headerTransparent}/>;
      default:
        return (
          <Link
            to={`/${slug && slug.length < 2 ? "" : slug + '/'}`}
            className={`${source && source =='mobilemenu' ? 'block py-4 px-8 font-semibold border-b border-gray-300' : `px-2 py-8 border-b-4 border-transparent ${headerTransparent ? 'text-white hover:border-brand-300 hover:text-brand-200' : 'text-gray-800 hover:text-brand-600  hover:border-brand-600 '}`}  font-semibold text-base   transition-colors duration-150 ease-in button-active-bhvr`}
          >
            {item.label}
          </Link>
        );
    }
  };
  return (
    <StaticQuery
      query={graphql`
        query MainSiteNavigationandSiteInfo {
          navigationJson {
            items {
              label
              pageurl
              items {
                type
                label
                page {
                  frontmatter {
                    id
                    slug
                    title
                  }
                }
                service {
                  frontmatter {
                    id
                    slug
                    title
                  }
                }
              }
              type
              type
              items {
                label
                type
                page {
                  id
                }
              }
              service {
                frontmatter {
                  id
                  slug
                  title
                }
              }
              page {
                frontmatter {
                  id
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { items } = data.navigationJson;
     
        return (
          <> 
          <header className={`${headerTransparent ? 'header--transparent-main' : ''}`}>
            <div className={`bg-gray-100 text-brand-500 hidden md:block relative z-20`}>
              <div className="font-display mx-auto py-3 text-sm container  px-4 md:px-6">
              <span className="block text-center md:text-left">{altslogan}</span>
              </div>
            </div>
            <div className={`${headerTransparent ? 'bg-white md:bg-transparent bg-transparent ' : 'bg-white md:shadow-sm '} ${bottomBorder && !headerTransparent ? 'border-b border-gray-100 md:shadow-none shadow-none': null} relative z-20`}>
              <div className="flex justify-between items-center px-4 md:px-6 py-5 lg:py-0 md:space-x-10 container mx-auto">
                <div>
                  <Link to="/" className="flex" title={companyname}>
                    <div style={{ width: "230px" }} className="header-logo">
                    {brandLogos.companylogo ? <div className="w-full"
                              dangerouslySetInnerHTML={{
                                __html: brandLogos.companylogo
                              }}></div>  : brandLogos.companylogodark ? <img src={brandLogos.companylogodark} className="w-full"/> : <span className={`font-semibold font-display text-xl leading-4 ${headerTransparent ? 'md:text-white' : ''}`}>{companyname}<span className={`text-base font-normal block ${headerTransparent ? 'md:text-gray-300' : 'text-gray-600'}`}>by GVD Renovations</span></span>}
                   
                    </div>
                  </Link>
                </div>

                {mainnavigationstyle == 'inline' ?   <div className="items-center font-display md:space-x-8 hidden lg:flex">
                    {items.map((item, i) => {
                      return <MenuItem key={i} item={item} type={item.type} headerTransparent={headerTransparent} />;
                    })}
                  </div>  : null}


                  {mainnavigationstyle == 'stacked' ?   
                <div className="hidden lg:block font-display text-center">
                  <span className="font-semibold block">
                    {slogan}
                  </span>
                  <span className="font-normal text-sm text-gray-800 block">
                    {subtagline}
                  </span>
                </div>  : null}


                <div className="hidden md:block font-display">
                  <div className="flex flex-row lg:flex-col xl:flex-row items-center">
                    <div className="pr-8 lg:pr-0 xl:pr-8 text-center">
                      <p className="mb-0 text-gray-700 leading-5 text-sm xl:text-base hidden">Give Us A Call Today</p>
                      <a className={`block font-bold text-lg md:text-xl lg:text-lg xl:text-2xl tracking-tight ${headerTransparent ? 'text-white' : 'text-brand-600'}`} href={`tel:${phonenumber}`}>{phonenumber} </a> 
                      <a className={`block font-bold text-xs md:text-sm lg:text-sm xl:text-lg tracking-tight ${headerTransparent ? 'text-white' : 'text-brand-500'}`} href={`sms:9163065170`}>Text (916) 306-5170</a> 

                    </div>
                    <div>
                      <button
                        onClick={() => setContactFormOpen(true)}
                        className="button-active-bhvr whitespace-no-wrap block cursor-pointer transition duration-150 ease-in-out bg-brand-500 focus:bg-brand-600 hover:bg-brand-600 focus:outline-none font-semibold px-4 py-2 lg:py-3 lg:px-5 rounded-full text-white font-display"
                      >
                        Free Estimate
                      </button>
                      <ContactFormModal
                        modalOpen={contactFormOpen}
                        onModalClose={setContactFormOpen}
                      />
                    </div>
                  </div>
                </div>

                <div className={`-mr-2 -my-2 ${mainnavigationstyle == 'stacked' ? 'md:hidden'  : 'lg:hidden'}`}>
                  <button
                    type="button"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    className="text-center p-2 rounded-lg text-gray-600 hover:bg-gray-200  focus:text-gray-700  focus:bg-gray-50  focus:outline-none  transition duration-150 ease-in"
                  >{mobileMenuOpen ? <svg
                    className="h-6 w-6 mx-auto"
                    x-description="Heroicon name: x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg> :  <svg
                      className="h-6 w-6 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>}
                   
                    <span className="block uppercase font-semibold" style={{fontSize:'.5rem'}}>{mobileMenuOpen ? 'Close' : 'Menu'}</span>
                  </button>
                </div>
              
              </div>

              <Transition
                  show={mobileMenuOpen}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  className={`absolute inset-x-0 transition transform origin-top-right z-50 ${mainnavigationstyle == 'stacked' ? 'md:hidden'  : 'lg:hidden'}`} 
                  style={{top:'100%'}}
                >
                  <div className=" shadow-lg font-display">
                    <div className="shadow-xs bg-white">
                     
                      <div>
                      {items.map((item, i) => {
                        return <MenuItem key={i} item={item} type={item.type} source={'mobilemenu'}/>;
                      })}
                        </div>
                    </div>
                  </div>
                </Transition>
            </div>
              

            {mainnavigationstyle == 'stacked' ?  <div className="relative bg-brand-500 text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
              <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
                {items.map((item, i) => {
                  return <MenuItem key={i} item={item} type={item.type} />;
                })}
              </div>
            </div>  : null}
           
          </header>

          <div className="sticky top-0 bg-brand-500  text-brand-900  shadow-sm md:border-none z-10 md:hidden">
          <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
          <div>
              <p className="mb-0 text-white font-display hidden sm:block">Give Us A Call Today</p>
              <a className="block font-semibold text-xl font-display text-brand-100" href={`tel:${phonenumber}`}>{phonenumber}</a> 
              <a className="block font-semibold text-sm font-display text-brand-100" href={`sms:9163065170`}>Text (916) 306-5170</a> 

            </div>
          <button
            onClick={() => setContactFormOpen(true)}
            className="button-active-bhvr block cursor-pointer transition duration-150 ease-in-out focus:outline-none font-semibold px-4 py-2 lg:py-3 lg:px-5 rounded-full text-brand-600 bg-white font-display"
          >
            Free Estimate
          </button>
            </div>
        
          </div>
        </>
        );
      }}
    />
  );
}

export default Navbar;
